export const employeeCardMap = { 
    "0016641042": "Abhitabya", 
    "0016645489": "Ajaya", 
    "0016643680": "Akankhya", 
    "0016643631": "Amit", 
    "0016641005": "Debapriya", 
    "0016643691": "Ebinazer", 
    "0016644274": "Jabir", 
    "0016641028": "Manas", 
    "0016643787": "Niveditha", 
    "0016642289": "Prathap", 
    "0016643696": "Ramit", 
    "0016641037": "Sai", 
    "0016643650": "Sumanth", 
    "0016643675": "Manjula", 
    "0016642263": "Srinibas", 
    "0015907134": "Swagat", 
    "0016641010": "Venkateshwar", 
    "0016644276": "Shareef", 
    "0016641053": "Parsuram" 
}