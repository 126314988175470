import logo from './logo.svg';
import './App.css';
import { read, utils, } from "xlsx";
import { employeeCardMap } from './card-employee';
import { DateTime, Duration } from "luxon"
import { useState } from 'react';
import { FilePond, File, registerPlugin } from 'react-filepond'
import 'filepond/dist/filepond.min.css'

function App() {
  const [document, setDocument] = useState([]);
  const [tables, setTables] = useState([]);
  const [result, setResult] = useState(false);

  function handleFile() {
    if (!document[0] || !document[0].file) {
      return;
    }

    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = e => {
      const bstr = e.target.result;
      const wb = read(bstr, { type: rABS ? "binary" : "array" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = utils.sheet_to_json(ws, { header: 1 });

      const report = {};
      const formated = data.slice(4).reduce((acc, item) => {
        if (item[10] !== "Successful") {
          return acc;
        }
        if (!acc.hasOwnProperty(item[0])) {
          acc[item[0]] = []
        }
        acc[item[0]].push({
          date: item[0],
          time: item[1],
          card: item[2],
          employee: employeeCardMap['00' + item[2]],
          type: item[9],
        })
        return acc;
      }, {});

      for (let date of Object.keys(formated)) {
        const entries = formated[date];
        const employeeEntries = entries.reduce((acc, item) => {
          if (!acc.hasOwnProperty(item.employee)) {
            acc[item.employee] = []
          }
          acc[item.employee].push(item)
          return acc;
        }, {});

        const sortedEmployeeEntries = Object.keys(employeeEntries).reduce((acc, item) => {
          acc[item] = employeeEntries[item].sort(function (a, b) {
            return a.time.localeCompare(b.time);
          })
          return acc;
        }, {})

        const employeeReport = {};
        for (const employee of Object.keys(sortedEmployeeEntries)) {
          let entry = '';
          let exit = ''
          let time = 0
          let lastLoginTime = '';
          let card = ''

          for (const empRecord of sortedEmployeeEntries[employee]) {
            if (lastLoginTime) {
              if (empRecord.type === "In") {
                continue;
              }

              const inTime = DateTime.fromISO(lastLoginTime)
              const outTime = DateTime.fromISO(empRecord.time)

              const diff = outTime.diff(inTime);

              // Find the time diff
              time += diff.as("minutes");

              lastLoginTime = ''
              exit = empRecord.time;
              continue;
            }

            if (!lastLoginTime) {
              if (!entry) {
                entry = empRecord.time;
                card = empRecord.card
              }
              if (empRecord.type === "In") {
                lastLoginTime = empRecord.time;
              }
            }
          }

          employeeReport[employee] = {
            name: employee,
            card,
            entry,
            exit,
            time: Duration.fromObject({ minutes: time }).toFormat("hh 'hours and' mm 'minutes'")
          }
        }

        report[date] = employeeReport
      }
      const final = Object.keys(report).map(item => ({
        date: item,
        value: Object.keys(report[item]).map(item2 => {
          return {
            ...report[item][item2]
          }
        })
      }))

      setTables(final)
      setResult(true)
      setDocument([])
    };
    debugger
    if (rABS) reader.readAsBinaryString(document[0].file);
    else reader.readAsArrayBuffer(document[0].file);
  }

  function setResulFalse() {
    setResult(false)
  }

  const hintStyle = {
    fontSize: 14
  }

  return (
    <>
      <div className='container'>
        {
          !result && <>
            <div className="App">
              <div className='row mt-5'>
                <div className='col-4'>
                  <FilePond
                    credits={false}
                    files={document}
                    onupdatefiles={setDocument}
                    labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                  />
                  <p className='mt-2 text-start fw-lighter' style={hintStyle}>Choose the file to be processed. It should be a valid access entry/exit transaction sheet in .xlsx format</p>
                  <button className='btn btn-primary float-start' onClick={handleFile}>Submit</button>
                </div>
              </div>
            </div>
          </>
        }
        {
          result && <>
            <button type="button" className='btn btn-primary float-end' onClick={setResulFalse}>New</button>
            <div>
              {tables.map(table => (
                <>
                  <div className='row mt-2'>
                    <div className='col-10'>
                      <h4>{table.date}</h4>
                    </div>
                    <div className='col-2'>

                    </div>
                  </div>
                  <table className='table'>
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Card</th>
                        <th scope="col">Entry</th>
                        <th scope="col">Exit</th>
                        <th scope="col">Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {table.value.map(record => (
                        <tr>
                          <td>{record.name}</td>
                          <td>{record.card}</td>
                          <td>{record.entry}</td>
                          <td>{record.exit}</td>
                          <td>{record.time}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              ))}
            </div>
          </>
        }
      </div>
    </>
  );
}

export default App;